import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';

import { history } from '_helpers';
import { Nav, PrivateRoute } from '_components';

const AppWrapper = () => {
    history.navigate = useNavigate();
    history.location = useLocation();

    let location = history.location;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/error" element={<Error />} />
            <Route path="/notfound" element={<NotFound />} />
            <Route path="/access" element={<Access />} />
            <Route
                path="*"
                element={
                    <PrivateRoute>
                        <App />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

export default AppWrapper;
