import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { acuerdosReducer } from './acuerdos.slice';

export * from './auth.slice';
export * from './users.slice';
export * from './acuerdos.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        acuerdos: acuerdosReducer
    }
});
