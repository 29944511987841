import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
// import * as dotenv from "dotenv"; // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
// dotenv.config();

// create slice
const name = 'users';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports
export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        users: {}
    };
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/usuarios-colaborador`;

    return {
        getAll: getAll()
    };

    function getAll() {
        return createAsyncThunk(`${name}/getAll`, async () => await fetchWrapper.get(baseUrl));
    }
}

function createExtraReducers() {
    return {
        ...getAll()
    };

    function getAll() {
        var { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.users = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.users = action.payload.datos;
            },
            [rejected]: (state, action) => {
                state.users = { error: action.error };
            }
        };
    }
}
