import CryptoJS from 'crypto-js';

const SEMILLA = 'REACT-JS';

class CryptoService {
    // Encrypt   Single value
    Encryptvalue(plaintext) {
        return CryptoJS.AES.encrypt(plaintext, SEMILLA).toString();
    }

    // Decrypt   Single value
    Decryptvalue(plaintext) {
        const bytes = CryptoJS.AES.decrypt(plaintext, SEMILLA);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    // Encrypt full Object value
    Encryptobject(object) {
        return CryptoJS.AES.encrypt(JSON.stringify(object), SEMILLA).toString();
    }

    // Decrypt full Object value
    Decryptobject(object) {
        const bytes = CryptoJS.AES.decrypt(object, SEMILLA);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
}

export default new CryptoService();
