import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { authActions } from '_store';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';

export const Login = () => {
    const dispatch = useDispatch();
    const authUser = useSelector((x) => x.auth.user);
    const authError = useSelector((x) => x.auth.error);

    const defaultValues = {
        username: '',
        password: ''
    };

    const toast = useRef(null);

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');

        if (authError) {
            toast.current.show({
                severity: 'error',
                summary: 'Error!',
                detail: authError.message
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authError]);

    const {
        control,
        formState: { errors },
        handleSubmit
        // reset,
    } = useForm({ defaultValues });

    const notificacion = (suceso, titulo, detalle) => {
        toast.current.show({ severity: suceso, summary: titulo, detail: detalle, life: 6000 });
    };

    const onSubmit = ({ username, password }) => {
        if (username === 'ebailetti' || username === 'varzapalo' || username === 'kbohorquez' || username === 'fcamarena') {
            // reset();
            return dispatch(authActions.login({ username, password }));
        } else {
            return notificacion('error', 'Error', 'El usuario no se encuentra autorizado para visualizar está página.');
        }
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    return (
        <div className="login-body">
            <Toast ref={toast} />
            <div className="card login-panel p-fluid">
                <div className="login-panel-content">
                    <div className="grid">
                        <div className="col-12 sm:col-6 md:col-6 logo-container">
                            <img src="assets/layout/images/logo-regatas.png" alt="logo-regatasadmin" />
                            <span className="guest-sign-in">Bienvenido, inicia sesión.</span>
                        </div>
                        <div className="col-12 username-container">
                            <span className="p-float-label">
                                <Controller
                                    name="username"
                                    control={control}
                                    rules={{ required: 'Username es requerido.' }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name}
                                            {...field}
                                            autoFocus
                                            className={classNames({
                                                'p-invalid': fieldState.invalid
                                            })}
                                        />
                                    )}
                                />
                                <label htmlFor="username" className={classNames({ 'p-error': errors.username })}>
                                    Username*
                                </label>
                            </span>
                            {getFormErrorMessage('username')}
                        </div>
                        <div className="col-12 password-container">
                            <span className="p-float-label">
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{ required: 'Password es requerido.' }}
                                    render={({ field, fieldState }) => (
                                        <Password
                                            id={field.name}
                                            {...field}
                                            toggleMask
                                            className={classNames({
                                                'p-invalid': fieldState.invalid
                                            })}
                                        />
                                    )}
                                />
                                <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>
                                    Password*
                                </label>
                            </span>
                            {getFormErrorMessage('password')}
                        </div>
                        {/* <div className="col-12 sm:col-6 md:col-6 rememberme-container">
                            <Checkbox checked={checked} onChange={(e) => setChecked(e.checked)} />
                            <label> Remember me</label>
                        </div> */}

                        {/* <div className="col-12 sm:col-6 md:col-6 forgetpassword-container">
                            <a href="/" className="forget-password">
                                Forget Password
                            </a>
                        </div> */}

                        <div className="col-12 sm:col-6 md:col-6">
                            <Button label="Ingresar" icon="pi pi-check" onClick={handleSubmit(onSubmit)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
