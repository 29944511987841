import axios from 'axios';
import CryptoService from './crypto.service';

let configCache = null;

function getConfig() {
    if (configCache) {
        return Promise.resolve(configCache);
    }

    const token = CryptoService.Decryptvalue(localStorage.getItem('REG1'));

    if (token) {
        configCache = {
            headers: {
                'x-token': token
            }
        };

        return Promise.resolve(configCache);
    } else {
        return Promise.reject(new Error('Token no encontrado'));
    }
}

const API_SERVICIOS = 'https://api.crl.pe/';
const API_REGATAS = 'https://apiregatas.regataslima.pe/';

export default class SharedService {
    async getCentroCosto() {
        const res = await axios.get(`${API_SERVICIOS}pool/ccosto-acuerdo`);
        return res.data;
    }

    async getMovimientos(codigo) {
        try {
            const config = await getConfig();
            const res = await axios.get(`${API_REGATAS}api/acuerdo-movimientos/${codigo}`, config);
            return res.data;
        } catch (error) {
            return error;
        }
    }
}
