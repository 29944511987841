import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { history, fetchWrapper } from '_helpers';
import CryptoService from '../service/crypto.service';
// import * as dotenv from "dotenv"; // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
// dotenv.config();

// create slice
const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports
export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation
function createInitialState() {
    let token;
    let user;

    if (localStorage.getItem('REG1')) {
        token = CryptoService.Decryptvalue(localStorage.getItem('REG1'));
        user = CryptoService.Decryptobject(localStorage.getItem('REG2'));
    }

    return {
        // initialize state from local storage to enable user to stay logged in
        token: token,
        user: user,
        error: null
    };
}

function createReducers() {
    return {
        logout
    };

    function logout(state) {
        state.token = null;
        state.user = null;

        localStorage.removeItem('REG1');
        localStorage.removeItem('REG2');
        history.navigate('/login');
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

    return {
        login: login()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ username, password }) =>
                await fetchWrapper.post(`${baseUrl}/login`, {
                    username,
                    password
                })
        );
    }
}

function createExtraReducers() {
    return {
        ...login()
    };

    function login() {
        var { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('REG1', CryptoService.Encryptvalue(user.token));
                localStorage.setItem('REG2', CryptoService.Encryptobject(user.usuario));
                state.token = user.token;
                state.user = user.usuario;

                // get return url from location state or default to home page
                const { from } = history.location.state || { from: { pathname: '/' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
