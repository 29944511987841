import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { acuerdoActions } from '_store';

import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';

import SharedService from '../service/shared.service';

const Dashboard = () => {
    const dispatch = useDispatch();
    const [registros, setRegistros] = useState([]);

    const [dropdownItem1, setDropdownItem1] = useState({ name: 'TODOS' });
    const [dropdownItems1, setDropdownItems1] = useState([{ name: 'TODOS' }]);

    const [dropdownItem2, setDropdownItem2] = useState({ name: 'TODOS' });
    const [dropdownItems2, setDropdownItems2] = useState([{ name: 'TODOS' }]);

    const { loading } = useSelector((state) => state.acuerdos.status);
    const { alerta } = useSelector((state) => state.acuerdos.mensaje);

    let acuerdosAll = useSelector((state) => {
        return state.acuerdos.data;
    });

    let acuerdos = useSelector((state) => {
        if (dropdownItem1.name === 'TODOS') {
            return state.acuerdos.data.filter((item) => item.acuerdo > 0 && !item.status);
        }

        return dropdownItem2.name === 'TODOS'
            ? state.acuerdos.data.filter((item) => item.acuerdo > 0 && !item.status && item.area === dropdownItem1.name)
            : state.acuerdos.data.filter((item) => item.acuerdo > 0 && !item.status && item.jefatura === dropdownItem2.name);
    });

    let data_count1 = acuerdos.filter((e) => e.situacion === 'CULMINADO');
    let data_count2 = acuerdos.filter((e) => e.situacion === 'EN EJECUCIÓN');
    let data_count3 = acuerdos.filter((e) => e.situacion === 'PENDIENTE' || e.situacion === 'INICIADO');

    const sharedService = new SharedService();

    const fetchData = async () => {
        try {
            const centroCosto = await sharedService.getCentroCosto();

            // const areasUnicas = [...new Set(acuerdosAll.map((item) => item.area))];
            const opciones = [...new Set(centroCosto.map((item) => item.DE_AREA))];

            // opciones = opciones.filter((item) => areasUnicas.includes(item));
            const items = [{ name: 'TODOS' }, ...opciones.map((str) => ({ name: str }))];

            setDropdownItems1(items);
            setRegistros(centroCosto);
        } catch (error) {
            console.error('Error al obtener datos:', error);
        }
    };

    useEffect(() => {
        dispatch(acuerdoActions.getAll());

        fetchData();
    }, [dispatch]); // Solo ejecutar una vez al montar el componente

    const myFilterFunction = (e) => {
        setDropdownItem1(e.target.value);
        setDropdownItem2({ name: 'TODOS' });

        let jefaturasUnicas = [...new Set(acuerdosAll.map((item) => item.jefatura))];
        let data = registros.filter((filtro) => filtro.DE_AREA === e.target.value.name);
        let opciones = [...new Set(data.map((item) => item.DE_DEPA))];

        opciones = opciones.filter((item) => jefaturasUnicas.includes(item));
        const items = [{ name: 'TODOS' }, ...opciones.map((str) => ({ name: str }))];

        setDropdownItems2(items);
    };

    return (
        <>
            {loading && (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <Message severity="info" text={alerta} />
                        </div>
                    </div>
                </div>
            )}
            {!loading && (
                <div className="grid layout-dashboard">
                    <div className="col-12">
                        <div className="card">
                            <h5>{dropdownItem1.name}</h5>
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-5">
                                    <div className="field col-12">
                                        <label htmlFor="state1">Área en consulta</label>
                                        <Dropdown id="state1" value={dropdownItem1} onChange={(e) => myFilterFunction(e)} options={dropdownItems1} optionLabel="name" placeholder="Seleccione.."></Dropdown>
                                    </div>
                                    <div className="field col-12">
                                        <label htmlFor="state2">Jefatura en consulta</label>
                                        <Dropdown id="state2" value={dropdownItem2} onChange={(e) => setDropdownItem2(e.value)} options={dropdownItems2} optionLabel="name" placeholder="Seleccione.."></Dropdown>
                                    </div>
                                </div>
                                <div className="field col-12 md:col-7">
                                    <Fieldset legend="Leyenda" toggleable>
                                        <p>
                                            <span className="acuerdo-badge situacion-PENDIENTE mr-2">PENDIENTE</span>
                                            Situación inicial del acuerdo incorporado a la data, no se ha realizado ninguna acción previa.
                                        </p>

                                        <p>
                                            <span className="acuerdo-badge situacion-EN_EJECUCIÓN mr-2">EN EJECUCIÓN</span>
                                            Situación que se indica para informar el avance en la ejecución del acuerdo.
                                        </p>
                                        <p>
                                            <span className="acuerdo-badge situacion-CULMINADO mr-2">CULMINADO</span>
                                            Situación que se indica para informar la culminación del acuerdo.
                                        </p>
                                    </Fieldset>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-6 lg:col-3">
                        <div className="overview-box card">
                            <div className="overview-box-value">{data_count1.length}</div>
                            <div className="overview-box-title">
                                <span className="acuerdo-badge situacion-CULMINADO">CULMINADO</span>
                            </div>
                            <img src="assets/layout/images/dashboard/graph-tasks.svg" alt="acuerdo" />
                            <div className="overview-box-status">
                                +{((data_count1.length / acuerdos.length) * 100).toFixed(2)}%<i className="pi pi-arrow-circle-up"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3">
                        <div className="overview-box card">
                            <div className="overview-box-value">{data_count2.length}</div>
                            <div className="overview-box-title">
                                <span className="acuerdo-badge situacion-EN_EJECUCIÓN">EN EJECUCIÓN</span>
                            </div>
                            <img src="assets/layout/images/dashboard/graph-purchases.svg" alt="acuerdo" />
                            <div className="overview-box-status">
                                +{((data_count2.length / acuerdos.length) * 100).toFixed(2)}%<i className="pi pi-arrow-circle-up"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3">
                        <div className="overview-box card">
                            <div className="overview-box-value">{data_count3.length}</div>
                            <div className="overview-box-title">
                                <span className="acuerdo-badge situacion-PENDIENTE mr-2">PENDIENTE</span>
                            </div>
                            <img src="assets/layout/images/dashboard/graph-issues.svg" alt="acuerdo" />
                            <div className="overview-box-status">
                                +{((data_count3.length / acuerdos.length) * 100).toFixed(2)}%<i className="pi pi-arrow-circle-up"></i>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Dashboard;
